.msg-page-tab {
  margin-bottom: 40px;
}

.msg-page-tab ul {
  list-style-type: none;
  display: flex;
  border-bottom: 2px solid #eed5ba;
  width: 320px;
  padding-bottom: 7px;
  padding-left: 0;
  transition: all 400ms ease;
}
.msg-page-tab ul li {
  margin-right: 25px;
  display: block;
  transition: all 400ms ease;
  cursor: pointer;
}

.msg-page-tab ul .userMsgActiveTb::after {
  content: "";
  display: block;
  width: 100%;
  background-color: #f4b740;
  height: 3.2px;
  position: relative;
  top: 8px;
  transition: all 400ms ease;
}
.sendMsgyfvSelect {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 30px;
  margin-top: 30px;
}

.sendMsgyfvSelect select {
  background: #089156;
  border-radius: 7px;
  height: 45px;
  width: 140px;
  border: none;
  color: white;
  padding: 10px;
  outline: none;
}

.send-user-msg-container {
  width: 550px;
  border-radius: 5px;
  padding: 20px;
}

.send-msg-conad {
  display: flex;
}

.send-msg-conad .giftIconImg img {
  width: 70px;
}

.msginfoCol1 {
  display: flex;
  margin-left: 15px;
  justify-content: space-between;
  width: 100%;
}

.msgsel4tn {
  display: flex;
  width: 95%;
  flex-direction: column;
}

.msgsel4tn select {
  width: 90%;
  height: 40px;
  outline: none;
  background: #fcfcfc;
  border: 1px solid rgba(189, 189, 189, 0.24);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: rgba(51, 77, 110, 0.5);
}

.msgsel4tn input {
  width: 90%;
  height: 40px;
  outline: none;
  background: #fcfcfc;
  border: 1px solid rgba(189, 189, 189, 0.24);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: rgba(51, 77, 110, 0.5);
}

.msgsel4tn label {
  font-size: 14px;
  color: #4e4b66;
}

.msgbodymainkji5 {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}

.msgbodymainkji5 textarea {
  outline: none;
  width: 100%;
  height: 170px;
  outline: none;
  background: #fcfcfc;
  border: 1px solid rgba(189, 189, 189, 0.24);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: rgba(51, 77, 110, 0.5);
}

.msgbodymainkji5 label {
  font-size: 14px;
  color: #4e4b66;
}

.sendMsg {
  width: 100%;
  position: relative;
  height: 45px;
  border: 1px solid rgba(189, 189, 189, 0.24);
  border-radius: 5px;
  margin-top: 15px;
}

.sendMsg input {
  width: 100%;
  height: 100%;
  opacity: 0;
  /* opacity: 0; */
  position: relative;
  z-index: 10;
}

.addimgsendmWrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 0px 0 10px;
  cursor: pointer;
}

.addimgsendm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #4e4b66;
}

.addimgsendm button {
  width: 100px;
  height: 100%;
  background: #ee7f0d;
  color: white;
  border-radius: 0 5px 5px 0px;
}

.sendimgtimer {
  display: flex;
  margin-top: 20px;
}

.timConIn {
  margin-right: 12px;
  display: flex;
  flex-direction: column;
}

.timConIn label {
  font-size: 14px;
  color: #4e4b66;
}

.timConIn input {
  width: 200px;
  height: 40px;
  outline: none;
  background: #fcfcfc;
  border: 1px solid rgba(189, 189, 189, 0.24);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: rgba(51, 77, 110, 0.5);
}

.sendmsgform {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 40px;
  width: 100%;
}

.sendmsgform button {
  background: #089156;
  border-radius: 4px;
  height: 45px;
  width: 140px;
  border: none;
  color: white;
  padding: 10px;
  outline: none;
}

.displayFileMsg {
  display: flex;
}
.displayFileMsg img {
  width: 70px;
  height: 50px;
  border-radius: 4px;
  margin-top: 10px;
}
