.add-coupon-button {
  background-color: #ee7f0d;
  margin-bottom: 26px;
  border: none;
  color: white;
  height: 51px;
  padding: 0 20px 0px 20px;
  border-radius: 10px;
  cursor: pointer;
  /* float: right; */
}

.view-coupon-button {
  background-color: #ee7f0d;
  /* margin-bottom: 26px; */
  border: none;
  color: white;
  height: 51px;
  padding: 0px 15px 0px 15px;
  border-radius: 10px;
  cursor: pointer;
  /* float: right; */
}

.status {
  padding: 5px 10px;
  border-radius: 50px;
  /* background: #000; */
}

.active {
  background-color: #5afd9b33;
}

.expired {
  background-color: #f6040480;
}

.redeemed {
  background-color: #0832f680;
}
