body {
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background: #e5e5e5;
  overflow-y: scroll;
}

.centra-margin {
  /* height: fit-content;
	width: calc(100vw - 300px);
	position: absolute;
	top: 100px;
	z-index: 4;
	padding-right: 0;
	overflow: hidden;
	position: absolute;
left: 250px; */
  /* display: flex;
	align-items: center;
	flex-direction: column; */
  position: relative;
  left: 284px;
  width: calc(100vw - 334px);
  top: 100px;
}

.App-header {
  background: #ffffff;
  width: 100vw !important;
  padding-left: 30px;
  height: 70px;
  /* padding: 0; */
  margin: 0;
  position: fixed;
  /* top: 0; */
  z-index: 6;
  width: 100vw !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  -webkit-filter: drop-shadow(2px 5px 3px rgba(128, 128, 128, 0.301));
  filter: drop-shadow(2px 5px 3px rgba(128, 128, 128, 0.301));
}

.navRoutes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  height: 58px;
}
.navRoutes span {
  text-decoration: none;
  color: #334d6e;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  margin: 0;
}
.navRoutes span,
a {
  text-decoration: none;
  color: #334d6e;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none !important;
  font-weight: 400;
}

.linkNav {
  margin-right: 15px;
}

.togMenu {
  color: #052555 !important;
  margin-left: 10px;
}

.appLogo {
  margin-right: auto;
  margin-left: 20px;
  background-image: url(../../../assets/image/logo1.png);
  width: 99px;
  height: 32px;
  background-size: contain;
  /* border-radius: 25px; */
  cursor: pointer;
  margin-top: 78px;
  background-repeat: no-repeat;
  margin-right: auto !important;
}

.toggleEl {
  margin-right: 90px;
  cursor: pointer;
}

.sidebar {
  width: 258px;
  height: 100vh !important;
  background: #e47e15;
  position: fixed;
  top: 68px;
  bottom: 0;
  z-index: 5;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(128, 128, 128, 0.329));
  filter: drop-shadow(2px 2px 2px rgba(128, 128, 128, 0.329));
  overflow-y: scroll;
  padding-bottom: 20px;
  overflow: hidden;
}
/* .sidebar::-webkit-scrollbar{
	width: 10px;
	background-color: #334D6E;
	cursor: pointer;
	visibility: hidden;
	
} */

.sidebarList {
  margin-top: 35px;
  list-style-type: none;
  margin-left: 40px;
  margin-bottom: 120px;
  font-weight: 300;
  line-height: 13px;
}

.sidebarList li {
  margin-bottom: 17px;
  cursor: pointer;
  color: #fff;
  font-size: 13px;
  margin-left: -35px !important;
  list-style-type: none;
  padding: 0;

  font-weight: 500;
  margin-bottom: 20px;
}

.sidebarList li i {
  margin-right: 13px;
  font-size: 18px;
  color: #fff;
}
/* 
.sidebarList li:first-child {
	font-weight: 600;
	margin-bottom: 17px;
	white-space: nowrap;
	margin-left: -35px !important;
	color: #fffeff;
	font-size: 17px;
} */

/* RESPONSIVENESS */
.dropdownlist {
  margin-left: -10px;
  margin-bottom: 20px;
}

.dropdownlist li a {
  cursor: pointer;
  color: #334d6e;
  font-size: 12px !important;
  margin-bottom: 0 !important;
}

.dropdownlist li {
  cursor: pointer;
  color: #334d6e;
  font-size: 13px !important;
  margin-bottom: 7px !important;
}

@media (min-width: 1025px) {
  .togMenu {
    display: none;
  }
}

@media (max-width: 1024px) {
  .SidebarDefault {
    display: none;
  }
  .toggleEl {
    margin-left: auto;
    margin-left: 30px;
  }
  .appLogo {
    margin-left: -10px;
    margin-right: auto;
    width: 40px;
  }
  .navItems {
    display: none;
  }
  .showSidebar {
    position: fixed;
    z-index: 99;
    -webkit-animation: showBar 300ms ease forwards;
    animation: showBar 300ms ease forwards;
  }
  @-webkit-keyframes showBar {
    0% {
      transform: translateX(-150px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes showBar {
    0% {
      transform: translateX(-150px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .hideSidebar {
    position: relative;
    z-index: 99;
    -webkit-animation: hideBar 300ms ease forwards;
    animation: hideBar 300ms ease forwards;
  }
  .togMenu {
    margin: 0;
    color: white;
    font-size: 27px;
    margin-left: 80px;
  }
  @-webkit-keyframes hideBar {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-260px);
    }
  }
  @keyframes hideBar {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-260px);
    }
  }

  .centra-margin {
    position: relative;
    width: 100vw;
    top: 100px;
    left: 0;
    right: 0;
    padding: 0 20px 0 20px;
    overflow-x: hidden !important;
  }
}

@media (max-width: 1024px) {
  .centra-margin {
    margin: 0 !important;
  }
}

.activeClass {
  cursor: pointer;
  color: #334d6e !important;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
}

.hoverActiveRoute {
  width: 170px;
  height: 13px;
  background-color: transparent;
  position: absolute;
  left: 0;
  display: block;
  z-index: 12;
  cursor: pointer;
  font-size: 11px;
}

.hoverActiveRoute li {
  font-size: 13px;
}

.hoverActiveRoute:hover::after {
  content: "";
  /* width: 30px;
	height: 20px;
	background-color: red;
	position: absolute;
	left: 0; */
  border-radius: 0 15px 15px 0;
  display: block;
  z-index: 10;
  -webkit-animation: showActiveRouteAnimation 200ms ease-in forwards;
  animation: showActiveRouteAnimation 200ms ease-in forwards;
  cursor: pointer;
}

@-webkit-keyframes showActiveRouteAnimation {
  0% {
    width: 30px;
    height: 10px;
    background-color: #fdad00;
    position: absolute;
    left: -20px;
  }

  100% {
    width: 30px;
    height: 20px;
    background-color: #fdad00;
    position: absolute;
    left: 0;
  }
}

@keyframes showActiveRouteAnimation {
  0% {
    width: 30px;
    height: 10px;
    background-color: #fdad00;
    position: absolute;
    left: -20px;
  }

  100% {
    width: 30px;
    height: 20px;
    background-color: #fdad00;
    position: absolute;
    left: 0;
  }
}

/* notification dropdown section */

.top-text-block {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: inherit !important;
  border-bottom: 1px solid #f4f4f4;
  position: relative;
}
.top-text-block:hover:before {
  content: "";
  width: 4px;
  background: #f05a1a;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
.top-text-block.unread {
  background: #ffc;
}
.top-text-block .top-text-light {
  color: #999;
  font-size: 0.8em;
}
.top-head-dropdown .dropdown-menu {
  width: 350px;
  height: 300px;
  overflow: auto;
}
.top-head-dropdown li:last-child .top-text-block {
  border-bottom: 0;
}
.topbar-align-center {
  text-align: center;
}
.loader-topbar {
  margin: 5px auto;
  border: 3px solid #ddd;
  border-radius: 50%;
  border-top: 3px solid #666;
  width: 22px;
  height: 22px;
  -webkit-animation: spin-topbar 1s linear infinite;
  animation: spin-topbar 1s linear infinite;
}
@-webkit-keyframes spin-topbar {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin-topbar {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropdownlist li a {
  color: white;
}

.userDetailsSidebar {
  margin-bottom: 20px;
}

.userDImageSideWrap {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userDImageSideWrap img {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.usernameWrap {
  color: white;
  font-size: 14px;
}
.useremail {
  color: white;
  font-size: 10px;
  text-align: center;
}
