.reportsWrapSelect {
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  gap: 30px;
}

.reportsWrapSelect select {
  width: 200px;
  padding: 10px;
  height: 40px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 3px;
}

.filterReportsTable {
  background-color: white;
}

.filterreportsitgd {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 30px;
  padding-top: 50px;
}

.filterreportsitgd ul {
  list-style-type: none;
  display: flex;
  padding-bottom: 17px;
}

.filterreportsitgd ul li {
  margin-right: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: rgba(128, 128, 128, 0.803);
  padding: 10px;
}

.filterreportsitgd ul li:last-child {
  margin-right: 0px;
}

.activeFil {
  color: white !important;
  background-color: orange;
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
}
