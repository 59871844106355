#Account {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
#Account .account-head {
  display: flex;
  justify-content: space-between;
}

.account-box {
  width: 24%;
  height: 129.11px;
  padding: 22px;
  color: #333333;
}
.account-box-top {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 15.51px;
}

.account-box-bot {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 129px5px;
}
.account-box-top2 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-top: 35px;
}
.account-witdraw-but {
  background: #000000;
  border-radius: 5px;
  width: 86px;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  color: #ff8c15;
}
.account-sett-img {
  height: 20px;
  width: 20px;
}

.account-modal {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
}
.account-modal-head {
  display: flex;
  width: 100;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 16px;
  padding-top: 16px;
}
.head-x {
  color: #cccccc;
  width: 13px;
  height: 13px;
}
.account-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.account-input input,
.account-input select {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 4px;
}

.account-input input:focus,
.account-input select:focus {
  background: #f7fafc;
  border: 1px solid #0a1857;
}
.account-input-flex {
  display: flex;
  justify-content: space-between;
}

.account-input input ::placeholder {
  color: #999999;
}
.acc-in-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  /* identical to box height */
  color: #000000;
}
.account-modal-butt {
  background: #ee7f0d;
  border-radius: 4px;
  text-align: center;
  padding: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #fafafa;
}
.with-head {
  display: flex;
  flex-direction: column;
}
.with-head-top {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  color: #333333;
}

.with-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #000000;
}

.with-check {
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
  margin-top: 26px;
  align-items: center;
}

.with-check input {
  border: 1.5px solid #ee7f0d;
  border-radius: 2px;
  margin-right: 10px;
}
