.table-wrap {
  padding: 50px;
  background-color: white;
  width: -moz-fit-content;
  width: 95%;
  margin-bottom: 40px;
  border-radius: 5px;
  margin-top: 15px;
}

.impt-i {
  color: rgba(255, 0, 0, 0.61);
}

.grp-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(185px, 1fr));
  grid-template-rows: 109px;
  grid-gap: 1rem;
  grid-auto-flow: dense;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
}

/* 
.grp-overview:last-child{
	margin-bottom: 20px;
} */

.grp-overview div {
  background: #ffffff;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  width: 100%;
  height: 110px;
  margin-top: 15px;
  /* filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.267)) */
}

@media (max-width: 490px) {
  .grp-overview {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    grid-template-columns: repeat(auto-fit, minmax(90vw, 90vw));
    width: 80vw;
  }

  .grp-overview div {
    width: 90vw;
  }
}

.grp-overview1 {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.grp-overview1 h1 {
  font-weight: 300;
  font-size: 15px;
  margin-top: 20px;

  /* text */

  color: #334d6e;
}

.grp-overview1 h1 i {
  margin-right: 5px;
}

.grp-overview1 p {
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;

  /* text */

  color: #334d6e;
}

.button-badge {
  background: #e47e15;
  text-decoration: none;
  /* padding: 1rem 1.1rem; */
  position: relative;
  display: inline-block;
  border-radius: 0.2rem;
  transition: all ease 0.4s;
  width: 47px;
  height: 43px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

.button-badge:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.badge-nt {
  position: absolute;
  top: -3px;
  right: -10px;
  font-size: 0.8em;
  background-color: red;
  width: 25px;
  height: 25px;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: -1px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50%;
  color: white !important;
  overflow: hidden;
}

.btn-success {
  background-color: #334d6e !important;
  border: none !important;
}

a {
  color: black !important;
  text-decoration: underline !important;
}
